@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light");

body {
  /* font-family: "Josefin Sans", cursive; */
  font-family: "Shadows Into Light", cursive;
  font-size: 24px;
}

blockquote {
  margin: 0;
}

blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

blockquote p::before {
  content: "\201C";
}

blockquote p::after {
  content: "\201D";
}
